import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Button from '../common/Button';
import PricingCard from '../common/PricingCard';
import H4 from '../typography/H4';
import Option from './Option';
import Wizard from './Wizard';

const iconYes = (
  <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16 text-green-500" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
  </svg>
);
const iconNo = (
  <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16 text-red-500" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
  </svg>
);

const Quiz = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [maxStep] = useState(7);
  const [points, setPoints] = useState(0);

  const data = useStaticQuery(
    graphql`
      query {
        autogenes: kurse(slug_slug: { eq: "autogenes-training-onlinekurs" }) {
          link
          price
          title
          frequenz
          features {
            field {
              label
            }
            value
          }
          desc
        }
        muskelentspannung: kurse(slug_slug: { eq: "progressive-muskelentspannung-onlinekurs" }) {
          link
          price
          title
          frequenz
          features {
            field {
              label
            }
            value
          }
          desc
        }
      }
    `
  );

  const handleOption = (option) => {
    if (option) {
      option((prevCount) => prevCount + 1);
    }
    setCurrentStep((prevCount) => prevCount + 1);
  };

  const resetQuiz = () => {
    setPoints(0);
    setCurrentStep(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Wizard currentStep={currentStep} className={`${currentStep === maxStep && 'hidden'}`}>
        <div>
          <H4 className="mb-6">Hast du schon Erfahrungen mit Entspannungsverfahren?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option svg={iconYes} answer="Ja" onClick={() => handleOption(setPoints)} />
            <Option svg={iconNo} answer="Nein" onClick={() => handleOption()} />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Wann brauchst du Entspannung am ehesten?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 17h1m16 0h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7m-9.7 5.7a4 4 0 0 1 8 0" />
                  <line x1={3} y1={21} x2={21} y2={21} />
                  <path d="M12 3v6l3 -3m-6 0l3 3" />
                </svg>
              }
              answer="Abends"
              onClick={() => handleOption(setPoints)}
            />
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={12} cy={13} r={7} />
                  <polyline points="12 10 12 13 14 13" />
                  <line x1={7} y1={4} x2="4.25" y2={6} />
                  <line x1={17} y1={4} x2="19.75" y2={6} />
                </svg>
              }
              answer="Zwischendrin"
              onClick={() => handleOption()}
            />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Was ist dein Ziel für den Kurs?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
                  <circle cx={7} cy={10} r={1} />
                </svg>
              }
              answer="Besser schlafen"
              onClick={() => handleOption(setPoints)}
            />
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="12" y1="6" x2="12" y2="3" />
                  <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
                  <line x1="18" y1="12" x2="21" y2="12" />
                  <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
                  <line x1="12" y1="18" x2="12" y2="21" />
                  <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
                  <line x1="6" y1="12" x2="3" y2="12" />
                  <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
                </svg>
              }
              answer="Spannung reduzieren"
              onClick={() => handleOption()}
            />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Wie schaltest du aktuell ab?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                  <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                  <line x1={3} y1={6} x2={3} y2={19} />
                  <line x1={12} y1={6} x2={12} y2={19} />
                  <line x1={21} y1={6} x2={21} y2={19} />
                </svg>
              }
              answer="Lesen, Ruhe, Schlaf"
              onClick={() => handleOption(setPoints)}
            />
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={5} cy={18} r={3} />
                  <circle cx={19} cy={18} r={3} />
                  <polyline points="12 19 12 15 9 12 14 8 16 11 19 11" />
                  <circle cx={17} cy={5} r={1} />
                </svg>
              }
              answer="Sport, Bewegung, Aktion"
              onClick={() => handleOption()}
            />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Wie findest du Meditation. Schon mal probiert?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option svg={iconYes} answer="Klappt gut" onClick={() => handleOption(setPoints)} />
            <Option svg={iconNo} answer="Ist nichts für mich" onClick={() => handleOption()} />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Leidest du unter:</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
                </svg>
              }
              answer="Gedankenkreisen oder Schlafstörungen"
              onClick={() => handleOption(setPoints)}
            />
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="12" y1="6" x2="12" y2="3" />
                  <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
                  <line x1="18" y1="12" x2="21" y2="12" />
                  <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
                  <line x1="12" y1="18" x2="12" y2="21" />
                  <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
                  <line x1="6" y1="12" x2="3" y2="12" />
                  <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
                </svg>
              }
              answer="Muskelverspannung, Rückenschmerzen"
              onClick={() => handleOption()}
            />
          </div>
        </div>
        <div>
          <H4 className="mb-6">Wieviel Zeit hast du fürs Training?</H4>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={12} cy={12} r={9} />
                  <polyline points="12 7 12 12 15 15" />
                </svg>
              }
              answer="20 - 40 Minuten"
              onClick={() => handleOption(setPoints)}
            />
            <Option
              svg={
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-16" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={12} cy={12} r={9} />
                  <polyline points="12 7 12 12 15 15" />
                </svg>
              }
              answer="10 - 20 Minuten"
              onClick={() => handleOption()}
            />
          </div>
        </div>
      </Wizard>
      <div className={`${currentStep === maxStep ? 'block' : 'hidden'}`}>
        <H4 className="mb-6">Wir empfehlen Dir:</H4>

        <div className="mb-5">
          {points > 3 ? (
            <PricingCard title={data.autogenes.title} desc={data.autogenes.desc} price={data.autogenes.price} priceFrequenz={data.autogenes.frequenz} features={data.autogenes.features} link={data.autogenes.link} />
          ) : (
            <PricingCard
              title={data.muskelentspannung.title}
              desc={data.muskelentspannung.desc}
              price={data.muskelentspannung.price}
              priceFrequenz={data.muskelentspannung.frequenz}
              features={data.muskelentspannung.features}
              link={data.muskelentspannung.link}
            />
          )}
        </div>
        <Button onClick={() => resetQuiz()} text="Quiz neustarten" />
      </div>
    </>
  );
};

export default Quiz;
