import { Link } from 'gatsby';
import React from 'react';
import H2 from '../typography/H2';
import H3 from '../typography/H3';
import P from '../typography/P';
import ModalPricingCard from './ModalPricingCard';
import { GatsbyImage } from 'gatsby-plugin-image';

const PricingCard = ({ title, desc, price, priceFrequenz, features, link, landingpage, image }) => {
  return (
    <div className="rounded-xl bg-white p-6 text-brand-gray shadow-xl">
      {image && <GatsbyImage image={image} alt={title} className="mb-4 w-full rounded-xl" />}
      <div className="">
        <div className="mb-5">
          <H2 className="font-bold">{title}</H2>
          {desc && <P>{desc}</P>}
        </div>
        {price && (
          <p className="font-display">
            <span className="text-5xl">{price} €</span>
            {/* space */}
            <span className="text-base"> /{priceFrequenz} inkl USt.</span>
          </p>
        )}
      </div>
      <div className={price ? 'pt-8' : 'pt-0'}>
        <H3 className="font-medium">Beinhaltet:</H3>
        <ul className="mt-6 mb-8 space-y-4">
          {features.map((feature, i) => {
            return (
              <li className="flex space-x-2 text-left text-sm" key={i}>
                {feature.field.label === 'Ausgegraut (nicht verfügbar)' ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 flex-shrink-0 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                  </svg>
                ) : (
                  <svg className="h-5 w-5 flex-shrink-0 text-brand-green" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )}
                <span>{feature.value}</span>
                {feature.field.label === 'Bald verfügbar' && <span className="inline-flex items-center rounded-xl bg-yellow-400 px-2 text-xs font-semibold text-white">Bald verfügbar</span>}
              </li>
            );
          })}
        </ul>
        <ModalPricingCard buttonText={`${title} buchen`} buttonClass="w-full" modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={link}></ModalPricingCard>

        {landingpage && (
          <div className="mt-3 text-center">
            <Link to={landingpage} className="text-brand-green hover:underline">
              Mehr Infos
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
