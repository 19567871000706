import React from 'react';

const Option = ({ answer, svg, onClick }) => {
  return (
    <div className="flex w-full self-stretch rounded-xl border border-gray-200 bg-white transition duration-300 hover:bg-gray-100 hover:shadow-none" role="button" tabIndex={0} onClick={onClick}>
      <span className={`relative w-full cursor-pointer self-stretch border border-transparent p-2 text-center transition-colors duration-300 md:p-5 `}>
        <div className="svg-checkbox-container">
          <div className="mb-3 text-center">{svg}</div>
          <div className="font-semibold md:text-lg">{answer}</div>
        </div>
      </span>
    </div>
  );
};

export default Option;
